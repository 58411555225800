
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');



.container {
    font-family: 'Roboto', sans-serif; 

    padding: 10px;
    text-align: center;
    border-radius: 5px;
    width: 100%; 
    margin: auto; 
   

  }
  
  .title {
    font-size: 4em; 
    margin-top: 100px;
    margin-bottom: 30px;
  }

  .subtitle {
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 2em; 
    font: bold;
  }

  .description {
    margin-bottom: 5px;
    font-size: 2em; 
    color: black;
    margin-bottom: 5px;
    color: black;
    line-height: 1.2;
    font: bold;
  }
  
  .buttons {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  
  .button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  
  
 
  
@media (min-width: 768px) {
  .container {
    padding: 0 40px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
  }
}





@media (max-width: 600px) {
  .title {
    font-size: 2em; 
  }
  .subtitle {
    font-size: 1.5em; 
  }
} 