/* CaseStudy.css */
.caseStudy-container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
}

.caseStudy-title {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

.caseStudy-subtitle {
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
}

.caseStudy-text {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: center; /* Center the text on all screen sizes */
}

.caseStudy-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.caseStudy-image {
  flex: 1;
  max-width: 35%;
  margin: 0 20px;
}

.caseStudy-problem {
  flex-direction: row-reverse;
}

.caseStudy-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  cursor: pointer;
}

/* Media Query for Mobile Devices */
@media (max-width: 767px) {
  .caseStudy-section {
    flex-direction: column; /* Stack elements vertically on mobile devices */
    align-items: center; /* Center align elements horizontally */
  }

  .caseStudy-image {
    max-width: 100%; /* Ensure the image doesn't exceed its container's width on mobile devices */
    margin: 0 auto; /* Center the image horizontally on mobile devices */
  }
}
