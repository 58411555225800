.addons-table {
  width: 55%; /* Adjust the width as needed, maybe 80% of the container */
  max-width: 960px; /* Adjust the max width as needed */
  margin: auto;
  border-collapse: collapse;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: Adds a subtle shadow for depth */
}
  
  .addons-header {
    display: flex;
    justify-content: space-between;
    background-color: #019467; /* Replace with your preferred color */
    color: white;
    padding: 10px;
  }
  
  .addon-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 10px;
  }
  
  .addon-description {
    flex-basis: 70%; /* Adjust the width ratio as needed */
  }
  
  .addon-price {
    flex-basis: 30%; /* Adjust the width ratio as needed */
    text-align: right;
  }
  
  .addon-note {
    display: block;
    font-size: smaller;
    color: #666;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .addon-row {
      flex-direction: column;
      text-align: left;
    }
  
    .addon-price {
      margin-top: 10px;
      text-align: left;
    }

    .table-container {
      padding: 10px;
    }
  
    .addons-table {
      width: 100%; /* On smaller screens, the table can take up more of the width */
    }
  }
  
  .table-container {
    display: flex;
    justify-content: center;
    padding: 20px;
  }