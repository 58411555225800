.calculator-container {
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 5px;
    border-color: black;
  }
  
  .calculator-form label {
    display: block;
    margin-bottom: 10px;
  }
  
  .calculator-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
  }
  

  .monthly-payment-container {
    margin-top: 20px;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .monthly-payment-amount {
    color: green ; /* You can choose any color you like */
    font-size: 1.2em;
  }
  
  
  .calculator-and-blog-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
  }
  
  .calculator-container, 
  .blog-container {
    flex: 1;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .text-centre {
    text-align: center;
  }
  
  .btn-primary {
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .calculator-and-blog-container {
      flex-direction: column;
    }
  
    .calculator-container, 
    .blog-container {
      width: 100%;
    }
  }
  
  
  