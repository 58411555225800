/* Herosection1.css 

@media screen and (max-width: 768px) {
    .hero-image {
      max-height: 50vh !important;
    }
  
    .hero-text {
      font-size: 1.5em !important;
    }
  }

  .hero-content {
    display: flex;
    flex-direction: column;
  }


  @media screen and (min-width: 768px) {
    .hero-content {
      flex-direction: row;
    }
  } */

  /* Herosection1.css */

/* Modify layout for smaller screens */
@media (max-width: 767px) {
  .hero-content {
    flex-direction: column;
  }

  .hero-text {
    order: 0;
  }

  .hero-image {
    order: -1;
  }
}