.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
}

.grid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
}

.grid-row {
  display: flex;
}

.grid-cell {
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.grid-cell.selected {
  background-color: #f0f0f0;
  color: black;
}

.words-to-find {
  display: flex;
  flex-wrap: wrap;  /* Allows the items to wrap onto multiple lines */
  justify-content: center;
  max-width: 400px;  /* Set a max-width to control when wrapping happens */
  margin: 0 auto;  /* Center the container */
  margin-bottom: 20px;
}


.words-to-find span {
  margin: 0 10px;
  cursor: pointer;
}

.words-to-find .found {
  text-decoration: line-through;
}

@media (max-width: 768px) {
  .grid-cell {
    width: 30px;
    height: 30px;
  }
}
