/* src/Components/ClosureBanner.css */
.closure-banner {
    background-image: url('../../../src/assets/images/bg/closure_banner.png'); /* Replace with your image path */
    background-repeat: no-repeat;
    background-size: cover  ;
    background-position: center;
    /*background-color: #01a479; /* Change this to your desired color */
    color: white; /* Change this to your desired color */
    padding: 15px;
    position: fixed;
    width: 100%;
    z-index: 1;
    text-align: center;
    top: 0;
    animation: slideDown 2s;

  }
  
  .closure-banner p {
    font-family: 'Arial', sans-serif; /* Change this to your desired font */
    color: black; /* Change this to your desired color */
    font-weight: 600;

  }
  
  .closure-banner button {
    background-color: white; /* Change this to your desired color */
    color: black; /* Change this to your desired color */
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: bold; /* Make the text bold */
    border-radius: 5px; /* Round the corners */
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); /* Add a box shadow */
    margin: 4px 2px;
    cursor: pointer;
    transition: all 0.3s ease 0s; /* Add a transition */
  }

  .closure-banner button:hover {
    background-color: #f44336; /* Change this to your desired color */
    color: white; /* Change this to your desired color */
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4); /* Change the box shadow on hover */
  }
  
  @keyframes slideDown {
    0% { transform: translateY(-100%); }
    100% { transform: translateY(0); }
  }


  body.with-banner {
    padding-top: 50px; /* Adjust this value to match the height of your banner */
  }

 
@media (max-width: 600px) {
  .closure-banner {
    padding: 25px; /* Reduce the padding on smaller screens */
  }

  .closure-banner p {
    font-size: 12px; /* Reduce the font size on smaller screens */
  }
}