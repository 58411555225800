.pricing-table {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap for smaller screens */
  gap: 20px; /* This creates consistent spacing between the items */
  justify-content: center; /* Center the items on the main axis */
  padding: 20px;
}

  
.pricing-option {
  flex: 1; /* Each option will take equal space */
  min-width: 250px; /* Minimum width of each option */
  max-width: 350px; /* Maximum width before wrapping */
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: Adds a subtle shadow for depth */
}
  .pricing-option h2 {
    color: #333;
    margin-bottom: 15px;
  }
  
  .pricing-option .price {
    font-size: 24px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .pricing-option button.get-started {
    background-color: #019467 ;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  
  .pricing-option ul {
    list-style: none; /* Removes default list styling */
    padding: 0; /* Removes default padding */
    margin-left: 0; /* Aligns the list to the left */
  }

  .pricing-option li {
    text-align: left; /* Aligns the text to the left */
    padding-left: 1em; /* Adds space before the bullet point */
    margin-bottom: 10px; /* Adds some space between the list items */
    position: relative; /* Needed to position the custom bullet */
  }
  
  .pricing-option li::before {
    content: '•'; /* The bullet point character */
    color: black; /* Bullet point color - change as needed */
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .pricing-option ul li {
    margin-bottom: 5px;
    text-align: left;
  }
  
  .checkmark {
    color: #019467;
  }
  
  .times {
    color: #019467;
  }
  
  .pricing-option .get-started {
    display: block; /* Makes the link take the full width of its container */
    margin: 20px 0; /* Adds top and bottom margin for spacing */
    text-align: center; /* Centers the text in the button */
  }

  @media (max-width: 768px) {
    .pricing-table {
      flex-direction: column; /* Stack the pricing options on small screens */
      align-items: center; /* Align items to the center */
    }
  
    .pricing-option {
      width: 100%; /* Options take full width on small screens */
      margin-bottom: 20px; /* Add space between stacked items */
    }
  
    /* Adjust button size for mobile */
    .pricing-option button.get-started {
      width: 100%;
      padding: 15px 0; /* Increase padding for a larger touch area */
    }
  }


  .get-started {
    background-color: #019467; /* Button color */
    color: white; /* Text color */
    padding: 10px 20px; /* Button padding */
    text-align: center;
    text-decoration: none; /* Remove underline from links */
    display: inline-block; /* Allow padding and dimensions */
    border: none; /* Remove border */
    border-radius: 5px; /* Optional: for rounded corners */
    cursor: pointer; /* Change mouse cursor to indicate clickable */
    /* Add more styling as needed */
  }

  .get-started:hover {
    background-color: whitesmoke; /* Darker shade on hover */
  }