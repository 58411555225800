/* Popup Container */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 60%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(50px);
  flex-direction: row;
  gap: 20px

}

.image-container {
  height: 300px;
  width: 300px;
  background-image: url('../../assets/images/app/onlinemeeting.jpg');
  background-size: cover;
  background-position: center;
  padding-right: 20px;
}

.content-container {
  flex: 1;
  display: flex;
  flex-direction: column; /* To align children vertically */
  justify-content: center; /* For horizontal centering */
  align-items: center; /* For vertical centering */
}

.image-wrapper {
  padding-right: 20px;
}




/* Headline */
.headline {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: black;
  font-family: 'Georgia, serif';
}

/* Description */
.description {
  font-size: 16px;
  margin-bottom: 30px;
  color: #777;
  font-family: 'Arial, sans-serif';
}



.btn-primary:hover {
  background-color: #0056b3;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  line-height: 1;
  color: #333;
  cursor: pointer;
}

/* Divider */
.divider {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 20px 0;
}

/* Fade Background */
.fade-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
  z-index: 9998;
}

.fade-background.active {
  background-color: rgba(0, 0, 0, 0.5);
}


@media (max-width: 768px) {
  .image-container {
    display: none;
  }

  .popup {
    width: 90%;
    padding: 20px;
    overflow: auto; /* This ensures any overflowing content becomes scrollable */
  }

  .headline {
    font-size: 20px; /* Reducing the font size a bit */
    margin-top: 10px; /* You can adjust this if needed */
  }
}
