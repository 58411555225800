.calculator-container {
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(240, 199, 199, 0.1);
    text-align: center;
    border-radius: 5px;
    border-color: black;
  }
  
  .calculator-form label {
    display: block;
    margin-bottom: 10px;
  }
  
  .calculator-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
  }
  
  .monthly-repayment-container {
    margin-top: 20px;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .monthly-repayment-amount {
    color: #007bff; /* Similar to Bootstrap primary color, can be changed */
    font-size: 1.2em;
  }
  
  .text-centre {
    text-align: center;
  }
  
 
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .calculator-container {
      width: 100%;
    }
  }
  


  .tax-savings-highlight {
    font-size: 18px;
    font-weight: bold;
    color:  white; /* Red color for emphasis */
    background-color: #01a479; /* Light red background */
    padding: 10px;
    border: 1px solid #01a479;
    border-radius: 4px;
    margin-top: 10px;
}


.tax-savings {
  font-size: 18px;
  font-weight: bold;
  color:  black; /* Red color for emphasis */
  background-color: rgb(246, 147, 147); /* Light red background */
  padding: 10px;
  border: 1px solid rgb(247, 172, 172);
  border-radius: 4px;
  margin-top: 10px;
}
