/* SuccessPage.css */

.success-container {
    background-color: black;
    background-size: cover;
    background-position: center;
    height: 100vh; /* Set the height to cover the entire viewport */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .success-content {
    background-color: rgba(255, 255, 255, 1); /* Add a semi-transparent white background to the content */
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  h2 {
    font-size: 24px;
    color: #333;
  }
  
  p {
    font-size: 18px;
    color: #555;
    margin-bottom: 20px;
  }
  
  Link {
    text-decoration: none;
    background-color: #007BFF;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  Link:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    /* Add mobile-specific styles here */
    .success-content {
      padding: 10px;
    }
    h2 {
      font-size: 20px;
    }
    /* Adjust other styles as needed */
  }
  