/* Custom CSS for adjusting the position of the navbar toggler */
.navbar {
    position: relative; /* Add this line */
}


@media (max-width: 768px) {
    .navbar-toggler {
        position: absolute;
        right: 10px; /* Adjusts the space from the right edge, can be changed as needed */
        top: 10px; /* Adjusts the vertical position, can be changed as needed */
    }
}

.top-bar {
    display: flex;
    /*justify-content: space-between;*/
    padding: 0px 0;
    background-color: white /* Change this to match your design */
}

.phone-number {
    position: fixed;
    top: 0;
    right: 10px; /* Adjust this value as needed */
    color: #333;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: right;
    margin-left: 700px;
    margin-right: 20px;
}

.phone-number .icon {
    font-size: 16px;
    margin-right: 5px;
    margin-top: 5px;
}

.phone-number .number {
    display: none;
}

@media (min-width: 768px) {
    .phone-number {
        position: static;
        right: auto;
    }

    .phone-number .number {
        display: inline;
    }
}

@media (max-width: 768px) {
    

    #site-header {
        padding-top: 15px; /* Adjust this value as needed */
    }
    .top-bar {
        position: fixed;
        width: 100%;
        z-index: 1000; /* This should be higher than the z-index of the Navbar */
        top: 0;
        right: 0;
        padding: 5px 0;
    }
    .phone-number {
        position: static;
        margin-left: 0;
    }

}

.phone-number, .btn-link {
    font-size: 12px; /* Adjust this value as needed */
    
}

.btn-link {
    color: #333;
    padding-right: 10px;
}


.highlighted-link {
    background-color: #01a479; /* or any color you want for the box */
    color: white; /* or any color you want for the text */
    padding: 10px;
    border-radius: 5px;
  }