.not-found-page {
  background-color: #f0f0f0; 
  /* background-image: url('/Users/personal/Desktop/codes/landing/winck-theme/src/assets/images/404-o.png');  Or any image you like */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh; 
  opacity: 0.8; 
}
  

.not-found-container {
    text-align: center;
    margin-top: 50px;
    padding-bottom: 100px;
    padding-top: 100px;
    background-color: #007bff;
  }
  
  .not-found-title {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .not-found-text {
    font-size: 18px;
    margin-bottom: 40px;
  }

  .home-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    border: 1px solid #007bff;
    color: #007bff;
    text-decoration: none;
    border-radius: 4px;
  }
  
  .home-link:hover {
    background-color: #007bff;
    color: white;
  }
  
  .not-found-page {
    position: relative;
    min-height: 100vh;
    text-align: center;
  }
  

  .not-found-page::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-color: transparent;

    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.9; /* Adjust the opacity as needed */
    z-index: -1;
  }