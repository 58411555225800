/* BookkeepingComponent.css */
.bookkeeping-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the start to handle varying heights */
  background-color: whitesmoke; /* Replace with your desired background color */
  padding: 20px;
}

.text-section {
  flex-basis: 60%; /* Adjust width as needed */
}

.header {
  color: #000; /* Replace with your desired color */
  margin-bottom: 10px;
}

.subheader {
  color: #000; /* Replace with your desired color */
  margin-bottom: 20px;
}

.description, .additional-content {
  color: #666; /* Replace with your desired color */
  line-height: 1.6;
}

.read-more-button {
  background-color: green; /* Replace with your desired button color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 120px;
}

.image-section, .text-section {
  flex: 1;
  padding: 20px; /* adjust as needed */
}

.text-section {
  text-align: justify;
} 
.image-placeholder {
  width: 100%;
  height: 300px; /* Adjust height as needed */
  background-color: #eee; /* Placeholder color, replace with an image */
  border-radius: 50%; /* Makes it circular */
  position: relative;
}

.image-placeholder::before {
  content: "";
  position: absolute;
  background-color: red; /* Replace with your desired overlay color */
  border-radius: 50%;
  width: 70%; /* Adjust size as needed */
  height: 70%; /* Adjust size as needed */
  top: 15%; /* Adjust position as needed */
  right: -20%; /* Adjust position as needed */
  z-index: -1;
}

.read-more-text {
  color: black;
  cursor: pointer;
  text-decoration: underline;
}


.content-section {
  display: flex;
  flex-direction: row-reverse;
}

@media (max-width: 768px) {
  .content-section {
    flex-direction: column;
  }
  .header {
    font-size: 40px; /* adjust as needed */
  }
}


.cta-button {
  display: inline-block;
  padding: 10px 50px;
  color: #fff;
  background-color: #01a479;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: black;
}

.image-section {
  width: 33.33%; /* 1/3rd of the container */
  overflow: hidden;
  height: 600px; /* hide anything that goes outside the .image-section */
}

.image-section img {
  width: 100%;
  height: 100%; /* make the image take up the full width of .image-section */
  object-fit: cover; /* maintain the aspect ratio of the image */
}

@media (max-width: 768px) {
  .image-section {
    width: 100%; /* make the image take up the full width of the container on small screens */
    height: auto; /* let the height adjust automatically based on the aspect ratio of the image */
  }
}