.chatbot-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: #01a479; /* Your specified green color */
  color: white; /* White text color */
  border: none; /* No border */
  border-radius: 100%; /* Circular button */
  width: 70px; /* Width and height of the button */
  height: 70px;
  box-shadow: 0 10px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  cursor: pointer; /* Pointer cursor on hover */
  display: flex; /* Flex to center content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  font-size: 20px; /* Size of the icon or text inside the button */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover */
}
  
  .chatbot-iframe {
    position: fixed;
    bottom: 80px; /* Adjust based on the size of your button */
    right: 20px;
    z-index: 999;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    transform: translateY(20px);
    width: 90vw; /* 100% of the viewport width */
    height: 80vh; /* 50% of the viewport height */
    transition: transform 0.3s ease-out;
    /* Add more styles if needed */
  }
  

  .chatbot-button:hover {
    background-color: #019467; /* Slightly darker shade on hover */
    transform: translateY(-2px); /* Small lift effect on hover */
  }

  .chatbot-iframe.open {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }


  @media (min-width: 768px) {
    .chatbot-iframe {
      width: 400px; /* Fixed width for larger screens */
      height: 600px; /* Fixed height for larger screens */
      /* Other styles for positioning and animation */
    }
  }