.calculator-buttons {
    text-align: center;
    margin-top: 20px;
  }
  
  .calculator-item {
    margin: 20px 0;
  }
  
  .calculator-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .calculator-description {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
  }
  